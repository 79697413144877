<template lang="">
    <div class="field">
        <div class="grid formgrid mt-8">
            <div class="field col-12">
                <label>Dados do solicitante</label>
                <div class="formgrid grid">
                    <div class="field col">
                        <InputText id="nomeSolicitante" v-model.trim="form.nomeSolicitante" placeholder="Nome"
                            required="true" autofocus
                            :class="{ 'p-invalid': submitted && !form.nomeSolicitante }" />
                        <small class="p-error" v-if="submitted && !form.nomeSolicitante">O nome do solicitante
                            é obrigatório.</small>
                    </div>
                    <div class="field col">
                        <InputText id="emailSolicitante" v-model.trim="form.emailSolicitante"
                            placeholder="Email" required="true" autofocus
                            :class="{ 'p-invalid': submitted && !form.emailSolicitante }" />
                        <small class="p-error" v-if="submitted && !form.emailSolicitante">O email do
                            solicitante é obrigatório.</small>
                    </div>
                    <div class="field col">
                        <InputMask id="telefoneSolicitante" v-model.trim="form.telefoneSolicitante"
                            mask="(99) 99999-9999" placeholder="Telefone" required="true" autofocus
                            :class="{ 'p-invalid': submitted && !form.telefoneSolicitante }" />
                        <small class="p-error" v-if="submitted && !form.telefoneSolicitante">O telefone do
                            solicitante é obrigatório.</small>
                    </div>
                </div>
            </div>
            <div class="field col-6">
                <label for="clientes">Clientes</label>
                <MultiSelectCliente id="clientes" v-model="form.clientes" />
            </div>
            <div class="field col-6">
                <label for="periodo">Período</label>
                <Calendar
                    id="periodo"
                    v-model="form.periodo"
                    dateFormat="mm/yy"
                    view="month"
                    :showIcon="true"
                    placeholder="Período"
                    autocomplete="off"
                />
            </div>
            <div class="field col-6">
                <p>Obs.: Somente o usuário que solicitar a convocação poderá finalizar todo o processo.</p>
            </div>
            <div class="col-12 p-3">
                <div class="flex justify-content-end">
                    <div class="flex align-items-center justify-content-center w-50rem font-bold text-white">
                        <Button
                            label="Buscar funcionários"
                            :loading="loadingBuscarFuncionarios"
                            icon="pi pi-check"
                            class="p-button p-button-primary mr-2 w-30rem"
                            @click="onClickBuscarFuncionarios"
                        />
                        <Button label="Cancelar" icon="pi pi-times" class="p-button p-button-danger" @click="onClickCancelar" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MultiSelectCliente from '../../clientes/components/MultiSelectCliente';
import ConvocacaoService from '../services/ConvocacaoService';
import { showSuccess, showError } from '@/utils/Toast';
import { getProfile } from '@/services/auth';

export default {
    components: { MultiSelectCliente },
    data() {
        return {
            $service: null,
            form: {},
            created: {},
            profile: {},
            submitted: false,
            loadingBuscarFuncionarios: false
        };
    },
    mounted() {
        this.$service = new ConvocacaoService();
        this.load();
    },
    methods: {
        async load() {
            this.profile = await getProfile();

            this.form.nomeSolicitante = this.profile.name;
            this.form.emailSolicitante = this.profile.email;
            this.form.telefoneSolicitante = this.profile.phone;
        },
        onClickCancelar() {
            this.$router.push(`/convocacao-exames/`);
        },
        async onClickBuscarFuncionarios() {
            this.loadingBuscarFuncionarios = true;
            this.submitted = true;
            try {
                const created = await this.create();
                this.created = created;
                showSuccess(this.$toast, 'Convocação criada com sucesso!');
            } catch (error) {
                showError(this.$toast, error);
            }

            try {
                await this.buscarFuncionarios(this.created.id);
                showSuccess(this.$toast, 'Busca de funcionários iniciada com sucesso.');
                this.$router.push(`/convocacao-exames/${this.created.id}/processando-convocacao`);
            } catch (error) {
                this.$router.push(`/convocacao-exames/${this.created.id}/processando-convocacao`);
                showError(this.$toast, error);
            }
            this.loadingBuscarFuncionarios = false;
        },
        async create() {
            const clienteIds = this.form?.clientes?.map((p) => p.id);

            const respCreate = await this.$service.create({
                ...this.form,
                clienteIds
            });

            return respCreate.data;
        },
        async buscarFuncionarios(id) {
            await this.$service.buscarFuncionarios(id);
        }
    }
};
</script>
